.accountMenuBox {
  display: flex;
  align-items: center;
  text-align: center;

  button {
    padding: 0 10px;

    &:hover {
      background: none;

      .MuiSvgIcon-root {
        transform: none !important;
      }
    }
  }
}
