.modal {
  position: relative;
}

.loading-overlay {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.35s ease;
  pointer-events: none;
  opacity: 0;

  &.loading {
    opacity: 1;
  }
}
