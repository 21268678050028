@import '~@styles/global.scss';

.intake {
  form {
    width: 100%;
  }

  &__pagination {
    color: rgba(84, 180, 228, 1);
    margin-bottom: 36px;
  }

  &__content {
    width: 100%;

    .company-list {
      margin: 24px 0px;

      & > .MuiButton-root {
        margin-bottom: 32px;
      }

      .company {
        background-color: #fafafa;
        padding: 12px 16px;
        margin-bottom: 6px;

        &-column {
          display: flex;
          flex: 1 0 auto;
          align-items: center;

          &-icon {
            div {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 1px solid #b9b9ba;
            }
          }

          &-right {
            justify-content: flex-end;
          }
        }

        span {
          color: #828282;
          margin-left: 10px;
          margin-bottom: 0;
        }

        .MuiButton-root {
          padding: 4px 8px;
          color: #c43c2c;
        }

        &-version-A {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-version-B {
          display: grid;
          grid-template-columns: 40px repeat(3, 1fr);
          gap: 1rem;

          .company-column-left {
            flex-direction: column;
            align-items: flex-start;

            span {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &__alert {
    transition: all 0.5s, opacity 0.25s !important;
    max-height: 200px;

    &.hidden,
    &--bottom.hidden {
      transform: translateY(-20px);
      opacity: 0;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      visibility: hidden;
    }

    &-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 20px 0;
    }
    &--bottom {
      display: inline-flex !important;

      .MuiAlert-action {
        display: none;
      }
    }
  }

  &__actions {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__submit {
    margin-left: auto;
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
  }
}

.fade-in {
  animation: fade-in 1s forwards;
  width: 100%;

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(24px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
