@import "~@styles/global.scss";

$animation_duration: 500ms;
$animation_bezier: cubic-bezier(0.39, 0.575, 0.565, 1);

.wrapper {
  margin: 150px 0px;
  padding: 0px 10%;
}

.bars {
  position: relative;
  width: 100%;
  height: 18px;
}

.bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width $animation_duration $animation_bezier;
}

#bar {
  &--total {
    background-color: #eeeeee;
    width: 100%;
    z-index: 1;
  }

  &--target {
    background-color: rgba(84, 180, 228, 1);
    width: 0%;
    z-index: 2;
  }

  &--current {
    background-color: $primary_main;
    width: 0%;
    z-index: 3;
  }
}

.legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform $animation_duration $animation_bezier;
  width: 150px;

  .tooltip {
    font-family: "Dax", sans-serif;
    color: $primary_main;
    font-size: 16px;
    font-weight: 400;
  }

  .percentage {
    font-family: "Dax", sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

#legend {
  &--start {
    position: absolute;
    bottom: -26px;
    left: 0px;
    color: rgba(185, 185, 186, 1);
  }

  &--end {
    position: absolute;
    bottom: -26px;
    right: 0px;
    color: rgba(185, 185, 186, 1);
  }

  &--target {
    position: absolute;
    top: -96px;
    left: 0px;
    .percentage {
      color: rgba(84, 180, 228, 1);
    }

    &::before {
      position: absolute;
      top: 72px;
      left: -2px;
      display: block;
      content: "";
      height: 24px;
      width: 2px;
      background-color: rgba(84, 180, 228, 1);
    }
  }

  &--current {
    position: absolute;
    top: 48px;
    left: 0px;
    .percentage {
      color: $primary_main;
    }
    &::before {
      position: absolute;
      top: -32px;
      left: -2px;
      display: block;
      content: "";
      height: 24px;
      width: 2px;
      background-color: $primary_main;
    }
  }
}
