@import "./breakpoints";
@import "./layout";
@import "./colors";
@import "./fonts";
@import "./typography";

.ï {
  position: relative;
  font-size: inherit;
  line-height: 1px;
}
.ï::after {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 3px;
  background: black;
  border-radius: 50%;
  content: ".";
}
.ï::before {
  content: ".";
  display: inline-block;
  position: absolute;
  left: 4px;
  top: 2px;
  height: 3px;
  width: 3px;
  background: black;
  border-radius: 50%;
}
