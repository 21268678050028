html,
body {
  margin: 0;
  padding: 0;
}

.no-margin {
  &--top {
    margin-top: 0;
  }

  &--bottom {
    margin-bottom: 0;
  }

  &--left {
    margin-left: 0;
  }

  &--right {
    margin-right: 0;
  }
}
