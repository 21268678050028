@import "~@styles/global.scss";

* {
  a {
    text-decoration: none;
  }
}

.navWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.closeDeclaration {
  @media (max-width: 960px) {
    display: none;
  }
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 64px;
  color: $primary_main;

  a {
    text-decoration: none;
  }

  span {
    position: relative;
    margin-left: 12px;
    font-size: 18px;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0%;
      height: 2px;
      background-color: $primary_main;
      transition: width 0.35s ease;
    }
  }

  svg {
    transition: transform 0.35s ease;
  }

  &:hover {
    cursor: pointer;
    svg {
      transform: rotate(180deg);
      cursor: pointer;
    }
    span {
      &::after {
        width: 100%;
      }
    }
  }
}
.imageWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 40px;
}
.navAligner {
  @media (max-width: 960px) {
    display: none;
  }
  height: 100%;
  padding-left: 40px;
  padding-right: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 30vw;
}
.navLinks {
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
}
.logoffButton {
  margin-left: 10px;
}
