@import "~@styles/global.scss";

.bouncing-loader {
  display: flex;

  & > div {
    width: 10px;
    height: 10px;
    margin: 3px 3px;
    border-radius: 50%;
    background-color: $primary_main;
    opacity: 1;
    animation: bouncing-loader 0.4s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-10px);
  }
}
