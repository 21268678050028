* {
  font-family: 'Dax', serif;
  letter-spacing: 0.8;
}

a {
  color: #37493b;
  &:hover {
    color: #241d6d;
  }
}

.link {
  text-decoration: underline;
}

h1 {
  font-size: 36px;
  line-height: 1.2em;
  font-weight: 500;
}
h2 {
  font-size: 25px;
  line-height: 1.25em;
  font-weight: 500;
}
h3 {
  font-size: 20px;
  line-height: 1.33em;
  font-weight: 500;
}
body {
  font-size: 18px;
  line-height: 1.1em;
  font-weight: 400;
}

p,
span,
a {
  font-size: 18px;
}

.text {
  &--medium {
    font-weight: 500;
  }

  &--bold {
    font-weight: 600;
  }
}
