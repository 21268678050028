// @import "~@styles/global.scss";

.add-subsidiary-modal {
  min-width: 500px;
  max-width: unset !important;

  .add-subsidiary-modal__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    height: 55px;
    padding: 16px;
    margin-bottom: 2px;
    cursor: pointer;

    &:disabled,
    &[disabled] {
      opacity: 0.6;
    }
  }

  .submit-subsidiary {
    display: flex;
    justify-content: space-between;
    .MuiFormGroup-root {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      min-width: -moz-fit-content;
      min-width: -webkit-fill-available;
      min-width: fit-content;
      .MuiFormControlLabel-root {
        border: 1px solid;
        padding: 6px 12px;
        .MuiRadio-root {
          padding: 0px;
          margin-right: 12px;
        }
      }
    }
  }
  .modal-actions {
    margin-top: 32px;
    display: flex;
  }
}
