@import "~@styles/global.scss";

div.options-grid {
  display: grid;
  gap: 24px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  grid-template-columns: repeat(var(--options-count), 1fr);

  &--horizontal {
    grid-template-columns: 1fr;
    --radio-wrapper-min-width: 4rem;
    --card-flex-direction: row;
    --card-padding-left: 0;
    --card-align-items: baseline;
  }

  .MuiFormControlLabel-label {
    display: none;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin: 0;

    &.unavailable {
      pointer-events: none;
      position: relative;

      .options-grid__card {
        opacity: 0.5;
      }

      &::after {
        z-index: 2;
        position: absolute;
        top: 128px;
        width: 100%;
        height: 132px;
        background-color: $secondary_main;
        content: "Helaas nog niet beschikbaar";
        font-weight: 400;
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__card {
    border: 1px solid $background_main;
    display: flex;
    flex-direction: var(--card-flex-direction, column);
    align-items: var(--card-align-items, center);
    justify-content: flex-start;
    transition: 0.35s ease;
    height: 100%;

    .content__wrapper {
      padding: 24px 24px 24px var(--card-padding-left, 24px);
    }

    &.version-A {
      padding: 24px 24px 24px var(--card-padding-left, 24px);

      .content__wrapper {
        padding: 0;
      }
    }

    &:hover {
      border-color: gray;
      cursor: pointer;
    }
    position: relative;

    &.selected {
      border-color: $primary_main;
    }

    .radio {
      display: flex;
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid $background_main;
      align-items: center;
      justify-content: center;

      &__wrapper {
        min-width: var(--radio-wrapper-min-width, 100%);

        &__version-A {
          display: flex;
          justify-content: center;
        }

        &__version-B {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 16px;
          gap: 1rem;
          border-bottom: 1px solid $background_main;

          .selected & {
            border-color: $primary_main;
          }
        }
      }

      &__selected {
        background-color: $primary_main;
        border-color: $primary_main;

        .dot {
          flex: 0 0 12px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 50%;
        }
      }
    }

    .MuiRadio-root,
    input {
      position: absolute;
      left: 50%;
      top: 24px;
      opacity: 0;
      height: 0 !important;
      width: 0 !important;
    }

    h3,
    p,
    span {
      width: 100%;
      text-align: left;
      margin-bottom: 0px;
    }
  }
}
