.breadcrumb {
  display: inline-flex;
  align-items: center;

  &__item.MuiButton-root {
    padding: 10px;

    & svg {
      transition: none !important;
      transform: unset !important;
    }
  }
  &__item--selected.MuiButton-root {
    font-weight: bold;
  }
}
