$background_default: #f5f5f5 !default;
$background_primary: #d3e7f4 !default;
$background_main: #B9B9BA !default;
$background_contrastText: #37493b !default;

$primary_main: #241d6d !default;
$primary_contrastText: #ffffff !default;

$secondary_main: #d3e7f4 !default;
$secondary_contrasttext: #241d6d !default;
