@import '~@styles/global.scss';

.form-layout {
  .form-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @include breakpoint(md) {
      grid-template-columns: 4fr 8fr;
    }

    .MuiGrid-root {
      margin-top: 0;
    }

    &.full-screen {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  .skeleton-wrapper {
    width: 100%;
    height: 180px;
    background-color: white;
    padding: 12px;
  }

  .form-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .button-grid {
    display: flex;
    justify-content: flex-end;
  }
}

.render-form {
  width: 100%;
  height: 100%;
  form {
    width: 100%;
  }
}

.fade-in {
  animation: fade-in 0.5s forwards;
  width: 100%;

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(24px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.pdf-export {
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 24px;
  }

  &__cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 1000px;

    h1 {
      margin-block: 12px;
    }

    h2 {
      margin-bottom: 12px;
    }
  }

  &__input {
    border: 1px solid $primary_main;
    padding: 12px;
  }
}
