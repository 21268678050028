@import "~@styles/global.scss";

.Dashboard-accordion {
  .summaryTitle {
    margin-top: 0;
    margin-bottom: 0;
  }

  .subsidiariesGrid {
    display: grid;
    grid-template-columns: 250px 300px 200px 1fr;
    gap: 12px;
    padding-top: 6px;
    align-items: center;
    &.table-head {
      color: gray;
    }
    .tooltip,
    button {
      justify-self: flex-end;
    }
  }

  .parent-requests {
    max-width: 60%;
    h1,
    h2 {
      margin-top: 0;
    }
    p {
      white-space: pre-line;
    }
  }

  &__buttons {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 12px;

    &__unconfirmed {
      margin-right: 16px;
      max-width: 250px;
      color: $primary_main;
      span {
        display: flex;
        text-align: right;
      }
    }

    button {
      margin-right: 16px;
    }

    > div {
      display: flex;
    }
  }
}
