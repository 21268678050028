@import "~@styles/global.scss";

.side-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  align-self: flex-start;
  height: auto;
  border-radius: 16px;
  overflow: hidden;

  &.hidden {
    display: none;
  }

  &__current {
    border-radius: 16px 16px 0px 0px;
    padding: 24px 24px 0px 24px;
    background: #d3e7f4;
    display: flex;
    flex-direction: column;

    .text--medium {
      font-size: 20px;
    }

    > span {
      margin-bottom: 12px;
    }
  }

  &__nav {
    background-color: #fff;
    padding: 16px 12px;
  }
  .section {
    display: flex;
    align-items: center;

    &.disabled {
      .MuiAccordionSummary-root & {
        pointer-events: none;
      }
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 12px;
  }

  .step {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    margin-left: 8px;
    align-items: center;

    span {
      z-index: 1;
      padding-left: 20px;
      font-size: 16px;
    }

    &.active {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 20px;
        z-index: 0;
      }
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.38);
      pointer-events: none;
    }

    &.section {
      margin-left: -6px;
      cursor: pointer;

      span {
        padding-left: 0;
        font-size: 18px;
      }
    }

    &.navigate {
      display: flex;
      align-items: center;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        .MuiStepLabel-root {
          span {
            color: $primary_main;
            cursor: pointer;
          }
        }
      }
    }
  }

  .MuiAccordion-root {
    border: none;

    &::before {
      background: none;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .MuiStep-root {
    margin-left: 30px;

    .MuiStepContent-root {
      border-left: none;
    }
  }

  .MuiStepLabel-vertical {
    flex-flow: row-reverse;

    .MuiSvgIcon-root {
      display: none;
    }

    .MuiSvgIcon-root.Mui-completed {
      display: block;
    }
  }

  .MuiStepConnector-root {
    display: none;
  }
}
